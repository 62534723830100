import { faClose, faEye, faEyeSlash, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { validateInputName, cleanInput } from "../helper";
import { disctrict, subDistrict } from "../page/Cards/fetch";
import Select from "react-select";
import { storeMerchant } from "../page/Merchant/fetch";

const CreateNewMerchant = (props) => {
  const [address, setAddress] = useState();
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [passwordShow, setPasswordShow] = useState(false);
  const [password, setPassword] = useState("");

  const [districtOptions, setDistrictOptions] = useState([]);
  const [subDistrictOptions, setSubDistrictOptions] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedSubDistrict, setSelectedSubDistrict] = useState(null);
  const [districtSearchTerm, setDistrictSearchTerm] = useState("");
  const [subDistrictSearchTerm, setSubDistrictSearchTerm] = useState("");

  const filterOption = (option, inputValue) => {
    const input =
      typeof inputValue === "string" ? inputValue.toLowerCase() : "";
    const label = String(option.label || "").toLowerCase();
    return label.includes(input);
  };

  useEffect(() => {
    const fetchDistrictOptions = async () => {
      try {
        const response = await disctrict(`?search=${districtSearchTerm}`);

        if (response.status) {
          const options = response.data.map((district) => ({
            value: district.id,
            label: district.name,
          }));
          setDistrictOptions(options);
        }
      } catch (error) {
        console.error("Failed to fetch district options:", error);
      }
    };

    if (districtSearchTerm) {
      const delayDebounceFn = setTimeout(() => {
        fetchDistrictOptions();
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [districtSearchTerm]);

  useEffect(() => {
    const fetchSubDistrictOptions = async () => {
      if (!selectedDistrict) return;

      try {
        const response = await subDistrict(
          `?search=${subDistrictSearchTerm}&district_id=${selectedDistrict.value}`
        );
        if (response.status) {
          const options = response.data.map((subDistrict) => ({
            value: subDistrict.id,
            label: subDistrict.name,
          }));
          setSubDistrictOptions(options);
        }
      } catch (error) {
        console.error("Failed to fetch sub-district options:", error);
      }
    };

    if (subDistrictSearchTerm) {
      const delayDebounceFn = setTimeout(() => {
        fetchSubDistrictOptions();
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [subDistrictSearchTerm, selectedDistrict]);

  const handleCreateMerchant = async (e) => {
    e.preventDefault();
    try {
      const params = {
        name: name,
        address: address,
        type: "merchant",
        phone: phone,
        password: password,
        district_id: selectedDistrict?.value,
        sub_district_id: selectedSubDistrict?.value,
      };

      const res = await storeMerchant(params);
      if (res.status) {
        alert("Berhasil membuat toko baru");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        alert(res.message);
      }
    } catch (error) {
      alert("Gagal membuat toko baru");
    }
  };

  return (
    <Modal {...props} centered={true} className="text-center">
      <Col className="modal-header d-flex justify-content-between align-items-center p-3">
        <h4 className="tx-red-bold-small mb-0">Buat Akun Toko</h4>
        <FontAwesomeIcon 
          icon={faClose} 
          color="red" 
          onClick={props.onHide} 
          style={{ cursor: 'pointer', marginLeft: 'auto' }}
        />
      </Col>
      <Modal.Body>
        <Col className="text-start mb-4">
          <Form onSubmit={handleCreateMerchant}>
            <Form.Label>
              Nomor Hp<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              onChange={(e) => setPhone(cleanInput(e.target.value))}
              maxLength={15}
              type="number"
              value={phone}
              className="input-search mb-2"
              placeholder="08xxx"
              required
            />
            <Form.Label>
              Nama Toko<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              onChange={(e) => setName(validateInputName(e.target.value))}
              maxLength={15}
              value={name}
              className="input-search mb-2"
              placeholder="Toko Nusantara"
              required
            />
            <Form.Label>
              Alamat Toko<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              onChange={(e) => setAddress(validateInputName(e.target.value))}
              maxLength={15}
              value={address}
              className="input-search mb-2"
              placeholder="Jl. bekasi .."
              required
            />
            <Form.Label>
              Kecamatan<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Select
              options={districtOptions}
              value={selectedDistrict}
              onInputChange={(inputValue) => setDistrictSearchTerm(inputValue)}
              onChange={(option) => {
                setSelectedDistrict(option);
                setSelectedSubDistrict(null);
                setSubDistrictOptions([]);
              }}
              placeholder="Cari Kecamatan"
              isClearable
              className="mb-4"
              required
              filterOption={filterOption}
            />
            <Form.Label>
              Kelurahan<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Select
              options={subDistrictOptions}
              value={selectedSubDistrict}
              required
              onInputChange={(inputValue) =>
                setSubDistrictSearchTerm(inputValue)
              }
              onChange={setSelectedSubDistrict}
              placeholder="Cari Kelurahan"
              isClearable
              isDisabled={!selectedDistrict}
              className="mb-4"
              filterOption={filterOption}
            />
            <Form.Label>
              Password<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <InputGroup className="input-form">
              <Form.Control
                type={passwordShow ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
                aria-label="search-product"
                style={{
                  border: "0.4px solid gray",
                  backgroundColor: "transparent",
                }}
              />
              <InputGroup.Text
                onClick={() => setPasswordShow(!passwordShow)}
                style={{
                  border: "0.4px solid gray",
                  backgroundColor: "transparent",
                }}
              >
                <FontAwesomeIcon
                  icon={passwordShow ? faEye : faEyeSlash}
                  color="gray"
                />
              </InputGroup.Text>
            </InputGroup>
            <span className="text-danger">
              <i>*untuk login ke website toko kartukeren</i>
            </span>

            <br />
            <br />
            <Col className="custom-checkbox d-flex text-start mb-4">
              <Form.Check required style={{ marginRight: "10px" }} />
              <Form.Label>
                Data yang di masukkan sudah benar
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
            </Col>
            <div className="d-flex justify-content-center">
              <Button type="submit" variant="danger">
                Buat Toko Baru
                <FontAwesomeIcon icon={faArrowRightLong} className="ms-2" />
              </Button>
            </div>
          </Form>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

export default CreateNewMerchant;
