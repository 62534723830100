import { Col, Container, Row, Table } from "react-bootstrap";
import { dateformat, formatNumberWithout } from "../../helper";
import {
  faArrowLeftLong,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";

const CardDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const datas = location?.state?.datas;

  return (
    <Container className="card-detail text-center" fluid>
      <Col className="nav text-center">
        <Row className="w-100 align-items-center">
          <Col
            xs="auto"
            className="text-left"
            onClick={() => navigate("/", { replace: false })}
          >
            <FontAwesomeIcon icon={faArrowLeftLong} size="lg" />
          </Col>
          <Col className="text-center flex-grow-1">
            <h5>Detail Kartu</h5>
          </Col>
          <Col xs="auto" className="text-right">
            <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
          </Col>
        </Row>
      </Col>
      <h4 className="tx-red-bold-small">Saldo Kamu!</h4>
      <Col className="col-card text-start mb-3">
        <Col>
          <h4 className="tx-card-number">No. Kartu</h4>
          <h4 className="tx-card-number">{datas?.card_number.split("_")[1]}</h4>
        </Col>
        <Col className="mt-auto">
          <h4 className="tx-card-balance">Saldo</h4>
          <h4 className="tx-card-balance-number">
            {datas?.amount === null
              ? "Kartu Tidak Aktif"
              : `${formatNumberWithout(datas?.amount)} Poin`}
          </h4>
        </Col>
      </Col>
      {/* {datas?.status === 0 && (
        <> */}
      <h4 className="tx-red-bold-small">Detail Transaksi Kamu!</h4>
      <Table responsive className="text-nowrap table-fixed text-start">
        <thead className="table-light">
          <tr>
            <th  scope="col">
              No
            </th>
            <th  scope="col">
              Waktu Transaksi
            </th>
            <th  scope="col">
              Total
            </th>
            <th  scope="col">
              Produk
            </th>
            <th  scope="col">
              Keterangan
            </th>
          </tr>
        </thead>
        {datas?.transaction_histories
          ? datas?.transaction_histories.map((item, index) => {
              return (
                <tbody key={index}>
                  <tr>
                    <td>{index + 1}</td>
                    <td>{dateformat(item.created_at)}</td>
                    <td>{formatNumberWithout(item.amount)}</td>
                    <td>{""}</td>
                    <td>
                      {item.transaction_type === 2
                        ? "Saldo Subsidi"
                        : "Transaksi"}
                    </td>
                  </tr>
                </tbody>
              );
            })
          : ""}
      </Table>
      {/* </> */}
      {/* )} */}
    </Container>
  );
};

export default CardDetail;
