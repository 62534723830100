import {
  faArrowAltCircleRight,
  faArrowCircleLeft,
  faSearch,
  faStore,
  faPlus,
  faEdit
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  // Image,
  InputGroup,
  Table,
} from "react-bootstrap";
import { merchant } from "./fetch";
import { formatNumberWithout } from "../../helper";
import CreateNewMerchant from "../../component/CreateNewMerchant";
import UpdateMerchant from "../../component/UpdateMerchant";

const Merchant = (props) => {
  const [merchantList, setMerchantList] = useState([]);
  const [paginPage, setPaginPage] = useState(1);
  const [showCreateModalMerchant, setShowCreateModalMerchant] = useState(false);
  const [showUpdateModalMerchant, setShowUpdateModalMerchant] = useState(false);
  const [dataUpdateModalMerchant, setDataUpdateModalMerchant] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  const handleUpdateMerchant = (data) => {
    setShowUpdateModalMerchant(true);
    setDataUpdateModalMerchant(data);
  };
  const getProduct = useCallback(async () => {
    try {
      const res = await merchant(`?page=${paginPage}&search=${searchQuery}`);
      if (res.status) {
        setMerchantList(res.data);
      }
    } catch (error) {}
  }, [paginPage, searchQuery]);

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  return (
    <Container className="merchant px-3">
      <CreateNewMerchant
        show={showCreateModalMerchant}
        onHide={() => setShowCreateModalMerchant(false)}
      />
      <UpdateMerchant
        data={dataUpdateModalMerchant}
        show={showUpdateModalMerchant}
        onHide={() => setShowUpdateModalMerchant(false)}
      />
      <div
        className="product-header mb-3 d-flex flex-row align-items-center justify-content-center"
        style={{ textAlign: "center" }}
      >
        <FontAwesomeIcon
          icon={faStore}
          style={{
            fontSize: "2.5rem",
            color: "#dc3545",
            marginRight: ".6rem",
          }}
        />
        <h2>Toko</h2>
      </div>
      {/* <Image
        src={require("../../assets/hero-home.png")}
        className="w-100 mb-3"
        style={{
          borderRadius: "10px",
          maxHeight: "17rem",
          objectFit: "cover",
        }}
      /> */}
      <Row className="align-items-end justify-content-between mb-3">
        <Col className="d-flex align-items-center">
          <InputGroup className="input-form" style={{ flex: 1 }}>
            <Form.Control
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Cari Toko Disini"
              aria-label="search-product"
              style={{
                border: "0px solid black",
                backgroundColor: "transparent",
              }}
            />
            <InputGroup.Text
              style={{
                border: "0px solid black",
                backgroundColor: "transparent",
              }}
            >
              <FontAwesomeIcon icon={faSearch} color="gray" />
            </InputGroup.Text>
          </InputGroup>
          <Button
            className="ms-2 p-0" // Add margin to the left of the button and remove padding
            variant="danger"
            onClick={() => setShowCreateModalMerchant(true)}
            style={{ width: '40px', height: '40px' }} // Set dimensions for the button
          >
            <FontAwesomeIcon icon={faPlus} /> {/* Add your add icon here */}
          </Button>
        </Col>
      </Row>

      <Table responsive className="text-nowrap table-fixed text-start">
        <thead className="table-light">
          <tr>
            <th  scope="col">
              No
            </th>
            <th
              
              scope="col"
            >
              Nama Toko
            </th>
            <th  scope="col">
              Kecamatan
            </th>
            <th  scope="col">
              Kelurahan
            </th>
            <th  scope="col">
              Alamat
            </th>
            <th  scope="col">
              Saldo Cash
            </th>
            <th  scope="col">
              Aksi
            </th>
          </tr>
        </thead>
        {merchantList?.data?.map((item, index) => {
          return (
            <tbody key={index}>
              <tr>
                <td >{index + 1}</td>
                <td >{item.name}</td>
                <td >
                  {item.district.name}
                </td>
                <td >
                  {item.sub_district.name}
                </td>
                <td >{item.address}</td>
                <td >
                  Rp {formatNumberWithout(item.balance)}
                </td>
                <td >
                  <Button
                    className=""
                    variant="danger"
                    onClick={() => handleUpdateMerchant(item)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                </td>
              </tr>
            </tbody>
          );
        })}
      </Table>
      <Col className="mb-3 mt-3 d-flex justify-content-between">
        <Col className="text-start px-3" xs={6}>
          <h6 className="tx-pagination-total">
            Menampilkan <b>{merchantList?.to}</b> dari{" "}
            <b>{merchantList?.total}</b> toko
          </h6>
        </Col>
        <Col className="text-end px-3 d-flex justify-content-between" xs={6}>
          <FontAwesomeIcon
            icon={faArrowCircleLeft}
            color="red"
            onClick={() => paginPage >= 1 && setPaginPage(paginPage - 1)}
          />
          <h6 className="tx-pagination-total">
            Page <b>{paginPage}</b> dari <b>{merchantList?.last_page}</b>
          </h6>
          <FontAwesomeIcon
            icon={faArrowAltCircleRight}
            color="red"
            onClick={() =>
              paginPage <= merchantList?.last_page &&
              setPaginPage(paginPage + 1)
            }
          />
        </Col>
      </Col>
    </Container>
  );
};

export default Merchant;
