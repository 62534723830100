import { POST } from "../../api/api";

async function login(param) {
  try {
    const res = await POST("auth/login", param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function logout() {
  try {
    const res = await POST(`auth/logout/`);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export { login, logout };
