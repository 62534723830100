import { useState } from "react";
import {
  Button,
  InputGroup,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Card,
} from "react-bootstrap";
import QrScanner from "react-qr-scanner";
import { useLocation } from "react-router-dom";
import {
  formatCardInput,
  formatNumberWithout,
  validateCardFormat,
} from "../../helper";
import { transaction } from "./fetch";

import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faArrowRightLong,
  faTimesCircle,
  faRotate,
  faQuestionCircle,
  faMoneyBill,
  faQrcode,
  faPercent,
  faArrowsLeftRightToLine,
} from "@fortawesome/free-solid-svg-icons";
// import { width } from "@fortawesome/free-solid-svg-icons/fa0";
// import { paymentMethod } from "../../utils";

const PayScanner = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [cameraPos, setCameraPos] = useState("environment");
  const [isFlipped, setIsFlipped] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showPending, setShowPending] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [scannedCardNumber, setScannedCardNumber] = useState("");

  const menus = location?.state?.menus;
  const [isDiscountApplied, setIsDiscountApplied] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState("Tunai");

  const handleScan = (data) => {
    if (data) {
      setCardNumber(data.text.replace(/^A1_/, ""));
      setScannedCardNumber(scannedCardNumber);
      // setShowModal(false);
    }
  };

  const handlePayment = async (data) => {
    if (secureLocalStorage.getItem("params")) {
      if (data) {
        setShowPending(true);
        try {
          if (cardNumber === null && validateCardFormat(cardNumber)) {
            alert("Nomor Kartu Kosong");
            setShowPending(false);
          } else {
            const params = {
              card_number: `A1_${cardNumber}`,
              products: menus.map((item) => ({
                product_id: item.id,
                qty: item.qyt,
              })),
            };

            const res = await transaction(params);
            if (res.status) {
              setShowPending(false);
              setTimeout(() => {
                navigate("/", { replace: false });
                alert("Pembayaran Berhasil");
              }, 500);
            } else {
              alert(res.errors);
              setShowPending(false);
            }
          }
        } catch (e) {
          setShowPending(false);
        }
      }
    } else {
      setShowPending(false);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handlePriceDiscount = () => {
    return menus.reduce((total, item) => total + item.discount, 0);
  };

  const handleSelect = (method) => {
    setSelectedMethod(method);
  };

  const handleContinue = () => {
    if (validateCardFormat(cardNumber)) {
      setIsDiscountApplied(true);
      // console.log(scannedCardNumber);
      setShowModal(false);
    } else {
      alert("Format nomor kartu tidak valid");
    }
  };
  const handleShowModal = () => {
    setCardNumber("");
    setIsDiscountApplied(false);
    setShowModal(true);
  };

  const textStyle = {
    color: "black",
    fontSize: ".9rem",
    fontWeight: 300,
  };

  return (
    <Container className="payscanner" fluid>
      <Modal show={showPending} centered className="p-5">
        <Col className="p-4 text-center">
          <h2>Tunggu Sebentar ..</h2>
        </Col>
      </Modal>
      <Modal show={showModal} centered onHide={() => setShowModal(false)}>
        <Modal.Body className="text-center">
          <Button
            variant="link"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 10,
              backgroundColor: "transparent",
              border: "none",
              color: "red",
              fontSize: "24px",
            }}
            onClick={() => setShowModal(false)}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </Button>
          <h3 className="mb-3">Scan Kartu</h3>

          <Col className="d-flex justify-content-between align-items-center text-center mb-2">
            <div style={{ position: "relative", width: "100%" }}>
              <QrScanner
                delay={300}
                onError={handleError}
                onScan={handleScan}
                constraints={{ video: { facingMode: cameraPos } }}
                style={{
                  transform: isFlipped ? "scaleX(-1)" : "none", // Apply horizontal flip
                  transition: "transform 0.3s ease-in-out", // Smooth transition
                }}
              />
              <Button
                variant="link"
                style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "50px",
                  marginRight: "5px",
                  zIndex: 10,
                  backgroundColor: "white",
                  border: "none",
                  color: "black",
                }}
                onClick={() => setIsFlipped(!isFlipped)} // Toggle flip
              >
                <FontAwesomeIcon icon={faArrowsLeftRightToLine} />
              </Button>
              <Button
                variant="link"
                style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                  zIndex: 10,
                  backgroundColor: "white",
                  border: "none",
                  color: "black",
                }}
                onClick={() =>
                  setCameraPos(
                    cameraPos === "environment" ? "user" : "environment"
                  )
                }
              >
                <FontAwesomeIcon icon={faRotate} />
              </Button>
            </div>
          </Col>
          <InputGroup className="mb-2 mt-4 input-search-card">
            <Form.Control
              placeholder="Nomor Kartu"
              id="card_number"
              value={cardNumber}
              onChange={(e) => setCardNumber(formatCardInput(e.target.value))}
              style={{
                padding: "10px",
                border: "0px solid black",
                backgroundColor: "transparent",
              }}
            />
            <InputGroup.Text
              onClick={cardNumber ? handleContinue : null}
              disabled={!cardNumber}
              style={{
                border: "0px solid black",
                backgroundColor: !cardNumber ? "gray" : "red",
                color: "white",
                cursor: "pointer",
                fontWeight: 500,
                opacity: !cardNumber ? 0.5 : 1,
              }}
            >
              Lanjut
            </InputGroup.Text>
          </InputGroup>
        </Modal.Body>
      </Modal>

      <Col className="nav text-center">
        <Row className="w-100 align-items-center">
          <Col
            xs="auto"
            className="text-left"
            onClick={() => navigate("/", { replace: false })}
          >
            <FontAwesomeIcon icon={faArrowLeftLong} size="lg" />
          </Col>
          <Col className="text-center flex-grow-1">
            <h5>Detail Pesanan</h5>
          </Col>
          <Col xs="auto" className="text-right">
            <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
          </Col>
        </Row>
      </Col>

      {/* <h4>Pesanan Anda</h4> */}
      <Col className="p-2">
        {menus.map((item, index) => {
          return (
            <Row key={index} className="row-cart-menu">
              <Col xs={4}>
                <Image
                  src={`${process.env.REACT_APP_API_URL}${item.image}`}
                  onError={(e) => {
                    e.target.src = require("../../assets/no-image-found.png");
                  }}
                  className="img-menu"
                />
              </Col>
              <Col xs={8} className="text-start">
                <h5
                  style={{
                    fontWeight: 500,
                    fontSize: "1rem",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  {item.name}
                </h5>
                <h6
                  className="mb-2"
                  style={{
                    color: "#959595",
                    fontSize: ".8rem",
                    fontWeight: 400,
                  }}
                >
                  {item.description}
                </h6>
                <Row className="d-flex justify-content-between">
                  <Col
                    xs={9}
                    style={{ width: "fit-content", fontSize: "1rem" }}
                  >
                    {/* <h4 className="tx-product-cross-price">
                      {formatNumberWithout(item.price)}
                    </h4> */}
                    <h5 className="mb-4 tx-product-price">
                      {formatNumberWithout(item.price)}
                    </h5>
                  </Col>
                  <Col xs={3} className="text-end">
                    <h6>{item.qyt} pcs</h6>
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        })}
      </Col>

      <Row className="col-payment-detail">
        <Col className="col-payment-detail-list">
          <h6 style={{ ...textStyle, marginBottom: ".9rem" }}>
            Ringkasan Pembayaran
          </h6>
          <Col className="d-flex justify-content-between">
            <h6 style={{ textStyle }}> Subtotal </h6>
            <h6 style={{ textStyle }}>
              {" "}
              Rp {formatNumberWithout(location?.state?.price ?? 0)}
            </h6>
          </Col>

          {/* Button untuk Scan Kartu Keren */}
          <Button
            className="w-100 mb-3"
            style={{
              backgroundColor: "#cf820b",
              color: "white",
              border: "none",
              borderRadius: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
            onClick={() => handleShowModal()}
          >
            Scan Kartu untuk Diskon
            <FontAwesomeIcon
              icon={faPercent}
              style={{
                position: "absolute",
                right: "16px",
              }}
            />
          </Button>

          {/* Tampilkan Potongan jika kartu sudah discan */}
          {isDiscountApplied && (
            <>
              <Col className="d-flex justify-content-between">
                <h6 style={textStyle}>Nomor Kartu</h6>
                <h6 style={textStyle}>{cardNumber}</h6>
              </Col>

              <Col className="d-flex justify-content-between">
                <h6 style={textStyle}>Diskon</h6>
                <h6 style={{ ...textStyle, fontWeight: 600, color: "green" }}>
                  - Rp {formatNumberWithout(handlePriceDiscount())}
                </h6>
              </Col>
            </>
          )}

          <hr className="dashed-hr" />
          <Col className="d-flex justify-content-between">
            <h6 style={{ ...textStyle, marginBottom: ".9rem" }}>Total</h6>
            <h6 style={{ ...textStyle, fontWeight: 600, marginBottom: "15px" }}>
              Rp{" "}
              {formatNumberWithout(
                (location?.state?.price ?? 0) -
                  (isDiscountApplied ? handlePriceDiscount() : 0)
              )}
            </h6>
          </Col>
          <h6 style={{ ...textStyle, marginBottom: ".9rem" }}>
            Metode Pembayaran
          </h6>
          <Card
            className="p-2"
            style={{ width: "100%", maxWidth: "400px", border: 0 }}
          >
            <Row className="text-center">
              {/* QRIS Payment Option */}
              <Col
                className={`payment-option ${
                  selectedMethod === "QRIS" ? "selected" : ""
                }disabled`}
                // onClick={() => handleSelect('QRIS')}
              >
                <FontAwesomeIcon icon={faQrcode} size="2x" />
                <p className="mt-1">QRIS</p>
              </Col>

              {/* Cash Payment Option */}
              <Col
                className={`payment-option ${
                  selectedMethod === "Tunai" ? "selected" : ""
                }`}
                onClick={() => handleSelect("Tunai")}
              >
                <FontAwesomeIcon icon={faMoneyBill} size="2x" />
                <p className="mt-1">Tunai</p>
              </Col>
            </Row>
          </Card>
          <Button
            className="mt-3"
            style={{
              backgroundColor: "#ff0000",
              borderColor: "#ff0000",
              width: "100%",
              maxWidth: "400px",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
            onClick={handlePayment}
          >
            Bayar Sekarang
            <FontAwesomeIcon
              icon={faArrowRightLong}
              size="lg"
              style={{
                position: "absolute",
                right: "16px",
              }}
            />
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default PayScanner;
