import { Col, Container, Form, Row, Table } from "react-bootstrap";
import React, {startTransition, useCallback, useEffect, useState } from "react";
import { home } from "./fetch";
import { formatNumberWithout } from "../../helper";
import FormSelect from "../../widget/form-select/FormSelect";
import { bulan } from "../../utils";
const Chart = React.lazy(() => import("react-apexcharts"), { ssr: false });


const Home = () => {
  const [dashboardData, setDashboard] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartOmset, setChartOmset] = useState([]);
  const [chartSellProduct, setSellProduct] = useState([]);
  const today = new Date().toISOString().split("T")[0];
  const todays = new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = monthNames[todays.getMonth()];
  const [startDate, setStartDate] = useState(today);
  const [monthFilter, setMonthFilter] = useState(monthName);

  const getKeys = (item) => {
    return Object.keys(item);
  };

  const getVal = (item) => {
    return Object.values(item);
  };

  const seriesOmzet = [
    {
      name: "Total Omset",
      data: getVal(chartOmset),
    },
  ];

  const optionsOmzet = {
    colors: ['#dc3545'],
    dataLabels: { enabled: false },
    labels: getKeys(chartOmset),
    chart: {
      type: "area",
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    xaxis: {
      type: "datetime",
      tickAmount: Math.ceil(getKeys(chartOmset).length / 5),
      labels: {
        formatter: function (value) {
          // Format tanggal secara manual ke "dd"
          return new Date(value).getDate().toString().padStart(2, "0");
        },
      },
      title: {
        text: "Tanggal", // Label for the x-axis
        style: {
          fontSize: '12px',
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return new Intl.NumberFormat('id-ID').format(value); 
        },
      },
    },
    tooltip: {
      x: {
        formatter: function (value) {
          // Format tooltip ke "dd"
          return new Date(value).getDate().toString().padStart(2, "0");
        },
      },
      y: {
        formatter: function (value) {
          // Format tooltip ke "dd"
          // return formatNumberWithout(value);
          return new Intl.NumberFormat('id-ID').format(value);
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 400,
            type: "area",
          },
          tooltip: {
            x: {
              formatter: function (value) {
                return new Date(value).getDate().toString().padStart(2, "0");
              },
            },
            y: {
              formatter: function (value) {
                return formatNumberWithout(value);
              },
            },
          },
        },
      },
    ],
  };

  const seriesSale = [
    {
      name: "Total Omset",
      data: getVal(chartSellProduct),
      backgroundColor: "white",
    },
  ];

  const optionsSale = {
    colors: ['#dc3545'],
    dataLabels: { enabled: false },
    labels: getKeys(chartSellProduct),
    chart: {
      type: "area",
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    xaxis: {
      type: "datetime",
      tickAmount: Math.ceil(getKeys(chartSellProduct).length / 5),
      labels: {
        formatter: function (value) {
          // Format tanggal secara manual ke "dd"
          return new Date(value).getDate().toString().padStart(2, "0");
        },
      },
      title: {
        text: "Tanggal", // Label for the x-axis
        style: {
          fontSize: '12px',
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return new Intl.NumberFormat('id-ID').format(value); 
        },
      },
    },
    tooltip: {
      x: {
        formatter: function (value) {
          // Format tooltip ke "dd"
          return new Date(value).getDate().toString().padStart(2, "0");
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 400,
            type: "area",
          },
          tooltip: {
            x: {
              formatter: function (value) {
                return new Date(value).getDate().toString().padStart(2, "0");
              },
            },
          },
        },
      },
    ],
  };

  const getDashboard = useCallback(async () => {
    setLoading(true);
    try {
      const res = await home(
        `?month_chart=${monthFilter}&day_top_five=${startDate}`
      );
      if (res.status) {
        // setDashboard(res.data);
        // setChartOmset(res.data?.chart?.omset);
        // setSellProduct(res.data?.chart?.sell_product);
        startTransition(() => {
          setDashboard(res.data);
          setChartOmset(res.data?.chart?.omset);
          setSellProduct(res.data?.chart?.sell_product);
        });
      }
    } catch (error) {
      console.error("Failed to fetch dashboard data:", error);
    } finally {
        setLoading(false);
      }
  }, [monthFilter, startDate]);

  useEffect(() => {
    // Memaksa reload halaman hanya sekali
    if (!sessionStorage.getItem("reloaded")) {
      sessionStorage.setItem("reloaded", "true");
      window.location.reload();
    }
  }, []);
  
  useEffect(() => {
    getDashboard();
  }, [getDashboard]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!dashboardData) {
    return <div>No data available.</div>;
  }


  return (
    <Container className="home" fluid>
      <Col className="py-4 text-center">
        <h4>Dashboard</h4>
        <Row>
          <Col xs={6} className="box-widget">
            <Col className="box-widget-in">
              <h6>Total Omzet</h6>
              <h2>
                <span className="currency-symbol">Rp. </span>
                {formatNumberWithout(dashboardData?.total_omset_all)}
              </h2>
            </Col>
          </Col>
          <Col xs={6} className="box-widget">
            <Col className="box-widget-in">
              <h6>Total Toko</h6>
              <h2>
                {formatNumberWithout(dashboardData?.total_merchant)}
              </h2>
            </Col>
          </Col>
          <Col xs={6} className="box-widget">
            <Col className="box-widget-in">
              <h6>Total Produk Terjual</h6>
              <h2>
                {formatNumberWithout(dashboardData?.total_product_sell)}
              </h2>
            </Col>
          </Col>
          <Col xs={6} className="box-widget">
            <Col className="box-widget-in">
              <h6>Total Kartu Aktif</h6>
              <h2>
                {formatNumberWithout(dashboardData?.total_card_activation)}
              </h2>
            </Col>
          </Col>
        </Row>
      </Col>
      <Col>
        <Col className="card-box mb-4">
          <Col className="d-flex justify-content-between align-items-center">
            <h6 className="py-1">Omzet ({monthFilter} 2024)</h6>
            <Form.Control
              className="form-chart w-25"
              placeholder="Bulan"
              type="date"
              as={FormSelect}
              options={bulan}
              value={monthFilter}
              onChange={(e) => setMonthFilter(e.target.value)}
            />
          </Col>
          <Chart
            options={optionsOmzet}
            series={seriesOmzet}
            type="area"
            toolbar={{ show: false }}
          />
        </Col>
        <Col className="card-box mb-4">
          <Col className="d-flex justify-content-between align-items-center">
            <h6 className="px-2 py-1">
              Produk Terjual ({monthFilter} 2024)
            </h6>
            <Form.Control
              className="form-chart w-25"
              placeholder="Bulan"
              type="date"
              as={FormSelect}
              options={bulan}
              value={monthFilter}
              onChange={(e) => setMonthFilter(e.target.value)}
            />
          </Col>
          <Chart
            options={optionsSale}
            series={seriesSale}
            type="area"
            background="#0000"
            height={350}
            toolbar={{ show: false }}
          />
        </Col>
        <h6>Filter</h6>
        <Col className="d-flex justify-content-center align-items-center mb-3">
          <Form.Control
            className="form-border"
            type="date"
            defaultValue={today}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </Col>

        <Col className="card-box text-center mb-4">
          <h4 className="py-3">Top 5 Kec. Aktivasi Terbanyak</h4>
          <Table responsive className="table-responsive text-nowrap table-fixed text-start">
            <thead className="table-light">
              <tr>
                <th  scope="col">
                  No
                </th>
                <th  scope="col">
                  Kec.
                </th>
                <th  scope="col">
                  Total Kartu
                </th>
              </tr>
            </thead>
            {dashboardData?.list?.top_5_districts_activation_card?.map(
              (item, index) => {
                return (
                  <tbody key={index}>
                    <tr>
                      <td >
                        {index + 1}
                      </td>
                      <td >
                        {item.district_name}
                      </td>
                      <td >
                        {item.user_count}
                      </td>
                    </tr>
                  </tbody>
                );
              }
            )}
          </Table>
        </Col>
        <Col className="card-box text-center mb-4">
          <h4 className="py-3">Top 5 Kec. Subsidi Terbanyak</h4>
          <Table responsive className="text-nowrap table-fixed text-start">
            <thead className="table-light">
              <tr>
                <th  scope="col">
                  No
                </th>
                <th  scope="col">
                  Kec.
                </th>
                <th  scope="col">
                  Total Subsidi
                </th>
              </tr>
            </thead>
            {dashboardData?.list?.top_5_districts_subsidi?.map(
              (item, index) => {
                return (
                  <tbody key={index}>
                    <tr>
                      <td >
                        {index + 1}
                      </td>
                      <td >
                        {item.district_name}
                      </td>
                      <td >
                        Rp {formatNumberWithout(item.total_revenue)}
                      </td>
                    </tr>
                  </tbody>
                );
              }
            )}
          </Table>
        </Col>
        <Col className="card-box text-center mb-4">
          <h4 className="py-3">Top 5 Toko Omzet Tertinggi</h4>
          <Table responsive className="text-nowrap table-fixed text-start">
            <thead className="table-light">
              <tr>
                <th  scope="col">
                  No
                </th>
                <th  scope="col">
                  Kec.
                </th>
                <th  scope="col">
                  Total Omzet
                </th>
              </tr>
            </thead>
            {dashboardData?.list?.top_5_districts_revenue?.map(
              (item, index) => {
                return (
                  <tbody key={index}>
                    <tr>
                      <td >
                        {index + 1}
                      </td>
                      <td >
                        {item.district_name}
                      </td>
                      <td >
                        Rp {formatNumberWithout(item.total_revenue)}
                      </td>
                    </tr>
                  </tbody>
                );
              }
            )}
          </Table>
        </Col>
        <Col className="card-box text-center mb-4">
          <h4 className="py-3">Top 5 Toko Belum Setoran</h4>
          <Table responsive className="text-nowrap table-fixed text-start">
            <thead className="table-light">
              <tr>
                <th  scope="col">
                  No
                </th>
                <th  scope="col">
                  Kec.
                </th>
                <th  scope="col">
                  Total Setoran
                </th>
              </tr>
            </thead>
            {dashboardData?.list?.top_5_districts_pending_cash?.map(
              (item, index) => {
                return (
                  <tbody key={index}>
                    <tr>
                      <td >
                        {index + 1}
                      </td>
                      <td >
                        {item.district_name}
                      </td>
                      <td >
                        Rp {formatNumberWithout(item.total_pending_cash)}
                      </td>
                    </tr>
                  </tbody>
                );
              }
            )}
          </Table>
        </Col>
      </Col>
    </Container>
  );
};

export default Home;
