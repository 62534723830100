import {
  faRotate,
  faCamera,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Col, Form, Image, InputGroup, Modal } from "react-bootstrap";
import QrScanner from "react-qr-scanner";
import { useNavigate } from "react-router-dom";
import { activation, cardDetail } from "./fetch";
import { formatCardInput, validateCardFormat } from "../../helper";
import InputDataCustomer from "../../component/CreateNewMerchant";

const Cards = (props) => {
  const navigate = useNavigate();

  const [cameraPos, setCameraPos] = useState("environment");
  const [showModal, setShowModal] = useState(false);
  // const [isCameraActive, setIsCameraActive] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [modalInputData, setModalInputData] = useState(false);

  const handleScan = (data) => {
    if (data) {
      handleDetailCard(data.text);
      setShowModal(false);
    }
  };

  const handleActivationCard = async (param) => {
    try {
      const params = {
        no_kk: param.no_kk,
        nik: param.nik,
        name: param.name,
        phone: param.phone,
        rt_rw: `${param.no_rt}/${param.no_rw}`,
        district_id: param.district,
        sub_district_id: param.sub_district,
        card_number: cardNumber,
        total_family_member: param.total_member,
      };

      const response = await activation(params);

      if (response.status) {
        alert("Berhasil Aktivasi Kartu, Kartu Sudah Bisa Dipakai");
        setModalInputData(false);
      } else {
        if (response.errors.no_kk[0] === "The no kk has already been taken.") {
          alert("Aktivasi Gagal, Nomor KK Sudah Digunakan");
        } else if (
          response.errors.nik[0] === "The nik has already been taken."
        ) {
          alert("Aktivasi Gagal, Nomor NIK Sudah Digunakan");
        } else {
          alert("Aktivasi Gagal, Periksa Kembali Data Yang Dimasukkan");
        }

        throw new Error("Request failed");
      }
    } catch (error) {}
  };

  const handleDetailCard = async (card) => {
    try {
      if (card === null && validateCardFormat(card)) {
        alert("Nomor Kartu Kosong");
        return;
      } else {
        setCardNumber(card);
        const params = {
          card_number: card,
        };

        const res = await cardDetail(params);

        if (res.status) {
          if (res.data.status === 0) {
            setModalInputData(true);
          } else {
            navigate("/card-detail", {
              replace: false,
              state: { datas: res.data },
            });
          }
        } else {
          alert(res.message);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  return (
    <Col className="notification px-3">
      <InputDataCustomer
        handleActivation={handleActivationCard}
        show={modalInputData}
        onHide={() => setModalInputData(false)}
      />
      <Modal show={showModal} centered onHide={() => setShowModal(false)}>
        <Modal.Body className="text-center">
          <Button
            variant="link"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 10,
              backgroundColor: "transparent",
              border: "none",
              color: "red",
              fontSize: "24px",
            }}
            onClick={() => setShowModal(false)}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </Button>
          <h3 className="mb-3">Scan Kartu</h3>
          <QrScanner
            delay={300}
            onError={handleError}
            onScan={handleScan}
            className="qr-scanner"
            constraints={{
              video: {
                facingMode: cameraPos,
              },
            }}
          />
          <Button
            variant="link"
            style={{
              position: "absolute",
              bottom: "10px",
              right: "10px",
              zIndex: 10,
              backgroundColor: "white",
              border: "none",
              color: "black",
            }}
            onClick={() =>
              setCameraPos(cameraPos === "environment" ? "user" : "environment")
            }
          >
            <FontAwesomeIcon icon={faRotate} />
          </Button>
          {/* <Button
              className="w-100 mb-2 mt-4"
              style={{
                backgroundColor: "#fd0100",
                border: "1px solid #fd0100",
              }}
              onClick={() =>
                setCameraPos(cameraPos === "environment" ? "user" : "environment")
              }
            >
              <FontAwesomeIcon style={{ marginRight: "10px" }} icon={faRotate} />
              Ganti Posisi Kamera
            </Button>
            <Button
              className="w-100"
              style={{
                backgroundColor: "#fd0100",
                border: "1px solid #fd0100",
              }}
              onClick={() => setIsCameraActive(false)}
            >
              <FontAwesomeIcon style={{ marginRight: "10px" }} icon={faClose} />
              Tutup Camera
            </Button> */}
        </Modal.Body>
      </Modal>

      <Image
        src={require("../../assets/hero-home.png")}
        className="w-100 mb-5"
        style={{
          borderRadius: "10px",
          maxHeight: "17rem",
          objectFit: "cover",
        }}
      />
      <InputGroup className="mb-2 input-search-card">
        <Form.Control
          placeholder="Masukkan Nomor Kartu"
          aria-label="search-product"
          id="card_number"
          value={cardNumber}
          onChange={(e) => setCardNumber(formatCardInput(e.target.value))}
          style={{
            padding: "10px",
            border: "0px solid black",
            backgroundColor: "transparent",
          }}
        />
        <InputGroup.Text
          onClick={() => handleDetailCard(`A1_${cardNumber}`)}
          style={{
            border: "0px solid black",
            backgroundColor: "red",
            color: "white",
            cursor: "pointer",
            fontWeight: 600,
          }}
        >
          Periksa
        </InputGroup.Text>
      </InputGroup>
      <Col className="d-flex justify-content-between align-items-center text-center mb-2">
        <hr style={{ backgroundColor: "rgb(207, 207, 207)", width: "42%" }} />
        <h6
          style={{
            marginBottom: "-1px",
            color: "rgb(207, 207, 207)",
            fontWeight: 200,
          }}
        >
          atau
        </h6>
        <hr style={{ backgroundColor: "rgb(207, 207, 207)", width: "42%" }} />
      </Col>
      <Button
        className="btn-activation w-100"
        onClick={() => setShowModal(true)}
      >
        <FontAwesomeIcon style={{ marginRight: "10px" }} icon={faCamera} />
        Scan Kartu
      </Button>
    </Col>
  );
};

export default Cards;
