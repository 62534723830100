import { Container, Image } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import { profile } from "./fetch";

const Profile = () => {
  const [profileData, setProfileData] = useState();

  const getProduct = useCallback(async () => {
    try {
      const res = await profile();
      if (res.status) {
        setProfileData(res.data);
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  return (
    <Container fluid className="profile text-center h-100">
      <Image
        src={require("../../assets/hero-home.png")}
        className="img-profile mb-4"
      />
      <h4 className="mb-3">Super Admin</h4>
      <h6>superadmin@gmail.com</h6>
      <h6 className="mb-5">{profileData?.user?.phone}</h6>
    </Container>
  );
};

export default Profile;
