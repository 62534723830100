import { faClose, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Modal, Tab, Tabs } from "react-bootstrap";
import { 
  // useEffect, 
  useState 
} from "react";
import { validateInputName, validateInputNumber } from "../helper";
import { updateProduct } from "../page/Product/fetch";

const UpdateProduct = (props) => {
  const [price, setPrice] = useState("");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [discount, setDiscount] = useState("");
  const [stock, setStock] = useState("");
  const [status, setStatus] = useState("");

  const handleCreateProduct = async (e) => {
    e.preventDefault();
    try {
      const params = { id: props?.data?.id };

      if (name !== "") {
        params.name = name;
      }
      if (desc !== "") {
        params.description = desc;
      }
      if (price !== "") {
        params.price = price;
      }
      if (discount !== "") {
        params.discount = discount;
      }
      if (stock !== "") {
        params.stock = stock;
      }
      if (status !== "") {
        params.status = status;
      }

      const res = await updateProduct(params);
      if (res.status) {
        alert("Berhasil merubah produk");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        alert(res.message);
      }
    } catch (error) {
      alert("Gagal merubah produk");
    }
  };

  const handleTabSelect = (key) => {
    if (key === "active") {
      setStatus(1);
    } else if (key === "not-active") {
      setStatus(0);
    }
  };

  return (
    <Modal {...props} centered={true} className="text-center">
      <Col className="modal-header d-flex justify-content-between align-items-center p-3">
        <h4 className="tx-red-bold-small mb-0">Edit Produk</h4>
        <FontAwesomeIcon 
          icon={faClose} 
          color="red" 
          onClick={props.onHide} 
          style={{ cursor: 'pointer', marginLeft: 'auto' }}
        />
      </Col>
      <Modal.Body>
        <Col className="text-start mb-4">
          <Form onSubmit={handleCreateProduct}>
            <Form.Label>
              Nama Produk<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              onChange={(e) => setName(validateInputName(e.target.value))}
              defaultValue={props?.data?.name}
              maxLength={15}
              className="input-search mb-2"
              placeholder="Ayam"
            />
            <Form.Label>
              Deskripsi Produk<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              onChange={(e) => setDesc(validateInputName(e.target.value))}
              maxLength={15}
              defaultValue={props?.data?.description}
              className="input-search mb-2"
              placeholder="Ayam"
            />
            Harga Normal<span style={{ color: "red" }}>*</span>
            <Form.Control
              onChange={(e) => setPrice(validateInputNumber(e.target.value))}
              // maxLength={15}
              type="number"
              defaultValue={props?.data?.price}
              className="input-search mb-2"
              placeholder=""
            />
            Subsidi<span style={{ color: "red" }}>*</span>
            <Form.Control
              onChange={(e) => setDiscount(validateInputNumber(e.target.value))}
              // maxLength={15}
              type="number"
              defaultValue={props?.data?.discount}
              className="input-search mb-2"
              placeholder=""
            />
            Stok<span style={{ color: "red" }}>*</span>
            <Form.Control
              onChange={(e) => setStock(validateInputNumber(e.target.value))}
              // maxLength={15}
              type="number"
              defaultValue={props?.data?.stock}
              className="input-search mb-3"
              placeholder=""
            />
            <Form.Label className="mb-1">
              Status<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Tabs
              className="mb-3 custom-tabs w-100"
              defaultActiveKey={
                props?.data?.status === 1 ? "active" : "not-active"
              }
              variant="pills"
              justify
              onSelect={handleTabSelect}
            >
              <Tab eventKey="active" className="w-100" title={"Aktif"} />
              <Tab
                eventKey="not-active"
                className="w-100"
                title={"Tidak Aktif"}
              />
            </Tabs>
            <div className="d-flex justify-content-center">
              <Button type="submit" variant="danger">
                Update Produk
                <FontAwesomeIcon icon={faArrowRightLong} className="ms-2" />
              </Button>
            </div>
          </Form>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateProduct;
