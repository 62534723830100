import {
  faArrowAltCircleRight,
  faArrowCircleLeft,
  faSearch,
  faBoxOpen,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  // Image,
  InputGroup,
  Table,
} from "react-bootstrap";
import { product } from "./fetch";
import { formatNumberWithout } from "../../helper";
import UpdateProduct from "../../component/UpdateProduct";

const Product = (props) => {
  const [productList, setProductList] = useState([]);
  const [paginPage, setPaginPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModalUpdateProduct, setShowModalUpdateProduct] = useState(false);
  const [dataUpdateProduct, setDataUpdateProduct] = useState({});

  const getProduct = useCallback(async () => {
    try {
      const res = await product(`?page=${paginPage}&search=${searchQuery}`);
      if (res.status) {
        setProductList(res.data);
      }
    } catch (error) {}
  }, [paginPage, searchQuery]);

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  const handleUpdateProduct = (data) => {
    setDataUpdateProduct(data);
    setShowModalUpdateProduct(true);
  };
  return (
    <Container fluid className="product px-3">
      <UpdateProduct
        data={dataUpdateProduct}
        show={showModalUpdateProduct}
        onHide={() => setShowModalUpdateProduct(false)}
      />
      <div
        className="product-header mb-3 d-flex flex-row align-items-center justify-content-center" 
        style={{ textAlign: "center" }}
      >
        <FontAwesomeIcon
          icon={faBoxOpen}
          style={{
            fontSize: "2.5rem",
            color: "#dc3545",
            marginRight: ".6rem",
          }}
        />
        <h2>
          Produk
        </h2>
      </div>
      {/* <Image
        src={require("../../assets/hero-home.png")}
        className="w-100 mb-3"
        style={{
          borderRadius: "10px",
          maxHeight: "17rem",
          objectFit: "cover",
        }}
      /> */}
      <InputGroup className="mb-3 input-form">
        <Form.Control
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Cari Barang Disini"
          aria-label="search-product"
          style={{
            border: "0px solid black",
            backgroundColor: "transparent",
          }}
        />
        <InputGroup.Text
          style={{
            border: "0px solid black",
            backgroundColor: "transparent",
          }}
        >
          <FontAwesomeIcon icon={faSearch} color="gray" />
        </InputGroup.Text>
      </InputGroup>
      <Table responsive className="text-nowrap table-fixed text-start">
        <thead className="table-light">
          <tr>
            <th  scope="col">
              No
            </th>
            <th
              
              scope="col"
            >
              Produk
            </th>
            <th  scope="col">
              Harga Normal
            </th>
            <th  scope="col">
              Subsidi
            </th>
            <th  scope="col">
              Stok
            </th>
            <th  scope="col">
              Status
            </th>
            <th  scope="col">
              Aksi
            </th>
          </tr>
        </thead>
        {productList?.data?.map((item, index) => {
          return (
            <tbody key={index}>
              <tr>
                <td >{index + 1}</td>
                <td >{item.name}</td>
                <td >
                  Rp {formatNumberWithout(item.price)}
                </td>
                <td >
                  Rp {formatNumberWithout(item.discount)}
                </td>
                <td >
                  {formatNumberWithout(item.stock)}
                </td>
                <td >
                  {item.status ? "Aktif" : "Tidak Aktif"}
                </td>
                <td >
                  <Button
                    className=""
                    variant="danger"
                    onClick={() => handleUpdateProduct(item)}
                  >
                    <FontAwesomeIcon icon={faEdit}   />
                  </Button>
                </td>
              </tr>
            </tbody>
          );
        })}
      </Table>
      <Col className="mb-3 mt-3 d-flex justify-content-between">
        <Col className="text-start px-3" xs={6}>
          <h6 className="tx-pagination-total">
            Menampilkan <b>{productList?.to}</b> dari{" "}
            <b>{productList?.total}</b> produk
          </h6>
        </Col>
        <Col className="text-end px-3 d-flex justify-content-between" xs={6}>
          <FontAwesomeIcon
            icon={faArrowCircleLeft}
            color="red"
            onClick={() => paginPage >= 1 && setPaginPage(paginPage - 1)}
          />
          <h6 className="tx-pagination-total">
            Page <b>{paginPage}</b> dari <b>{productList?.last_page}</b>
          </h6>
          <FontAwesomeIcon
            icon={faArrowAltCircleRight}
            color="red"
            onClick={() =>
              paginPage <= productList?.last_page && setPaginPage(paginPage + 1)
            }
          />
        </Col>
      </Col>
    </Container>
  );
};

export default Product;
