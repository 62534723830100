const paymentMethod = [
  {
    id: 2,
    name: "Kartu",
    type: "scan-qr",
    discount_type: 1,
    discount: 5,
    max_discount: 50000,
    min_discount: 9999,
    merchant_type: "merchant",
    admin_fee: null,
    status: "1",
    created_at: "2024-07-18T06:24:20.000000Z",
    updated_at: "2024-07-18T06:24:20.000000Z",
  },
  {
    id: 1,
    name: "Non Kartu",
    type: "cash",
    discount_type: 1,
    discount: 0,
    max_discount: null,
    min_discount: null,
    merchant_type: "merchant",
    admin_fee: null,
    status: "1",
    created_at: "2024-07-18T06:24:20.000000Z",
    updated_at: "2024-07-18T06:24:20.000000Z",
  },
];

const optionFamilyMember = [
  { value: "1", label: "1 Orang" },
  { value: "2", label: "2 Orang" },
  { value: "3", label: "3 Orang" },
  { value: "3", label: "3 Orang" },
  { value: "4", label: "4 Orang" },
  { value: "5", label: "5 Orang" },
  { value: "6", label: "6 Orang" },
  { value: "7", label: "7 Orang" },
  { value: "8", label: "8 Orang" },
  { value: "9", label: "9 Orang" },
];

const bulan = [
  { value: "January", label: "Januari" },
  { value: "February", label: "Februari" },
  { value: "March", label: "Maret" },
  { value: "April", label: "April" },
  { value: "May", label: "Mei" },
  { value: "June", label: "Juni" },
  { value: "July", label: "Juli" },
  { value: "August", label: "Agustus" },
  { value: "September", label: "September" },
  { value: "October", label: "Oktober" },
  { value: "November", label: "November" },
  { value: "December", label: "Desember" },
];

export { paymentMethod, optionFamilyMember, bulan };
