import { GET, POST } from "../../api/api";

async function cardDetail(param) {
  try {
    const res = await POST(`card/detail-card`, param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function disctrict(param) {
  try {
    const res = await GET(`region/district?search=bekasi`, param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function subDistrict(param) {
  try {
    const res = await GET(`region/sub-district?district_id=${param}`);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function activation(param) {
  try {
    const res = await POST("card/activation", param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export { cardDetail, disctrict, activation, subDistrict };
