import { GET, POST, PUT } from "../../api/api";

async function merchant(param) {
  try {
    const res = await GET("merchant", param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function storeMerchant(param) {
  try {
    const res = await POST("merchant", param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function updateMerchant(param) {
  try {
    const res = await PUT("merchant", param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export { merchant, storeMerchant, updateMerchant };
