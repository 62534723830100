import React from "react"; // { useContext }
import { Nav, NavItem } from "reactstrap";
import { NavLink, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOut,
  // faHistory,
  // faContactCard,
  // faStore,
  // faBox,
  // faBoxOpen,
  // faPerson,
  // faUser,
  // faSimCard,
  // faIdCard,
  // faIdCardClip,
} from "@fortawesome/free-solid-svg-icons";
// import { Col, Image } from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";
import { logout } from "../page/Auth/fetch";
// import { BalanceContext } from "../page/BalanceContext";
import boxIcon from "../assets/icons/box.svg";
import boxActiveIcon from "../assets/icons/box-aktif.svg";
import homeIcon from "../assets/icons/home.svg";
import homeActiveIcon from "../assets/icons/home-aktif.svg";
import berandaIcon from "../assets/icons/beranda.svg";
import berandaActiveIcon from "../assets/icons/beranda-aktif.svg";
import kartuIcon from "../assets/icons/kartu.svg";
import kartuActiveIcon from "../assets/icons/kartu-aktif.svg";
import profilIcon from "../assets/icons/profil.svg";
import profilActiveIcon from "../assets/icons/profil-aktif.svg";

// Tab Konfigurasi
const tabs = [
  {
    route: "/",
    icon: homeIcon,
    activeIcon: homeActiveIcon,
    label: "Beranda",
  },
  {
    route: "/cards",
    icon: kartuIcon,
    activeIcon: kartuActiveIcon,
    label: "Kartu",
  },
  {
    route: "/products",
    icon: boxIcon,
    activeIcon: boxActiveIcon,
    label: "Produk",
  },
  {
    route: "/merchants",
    icon: berandaIcon,
    activeIcon: berandaActiveIcon,
    label: "Toko",
  },
  {
    route: "/profile",
    icon: profilIcon,
    activeIcon: profilActiveIcon,
    label: "Profil",
  },
];

// Fungsi Handle Logout
const handleLogout = async () => {
  try {
    const res = await logout();
    if (res.status) {
      ["token"].forEach((item) => secureLocalStorage.removeItem(item));
      window.location.reload();
    } else {
      alert("Gagal Logout");
    }
  } catch (error) {
    ["token"].forEach((item) => secureLocalStorage.removeItem(item));
    window.location.reload();
  }
};

// Komponen Utama
const Navigation = () => {
  return (
    <div>
      <nav
        className="navbar navbar-expand-md navbar-light fixed-top"
        role="navigation"
      >
        <div className="container p-0">
          <NavBarContent />
        </div>
      </nav>
      <BottomTabNavigator />
    </div>
  );
};

function NavBarContent() {
  // const location = useLocation();
  const merchantType = secureLocalStorage.getItem("type");
  const merchantId = secureLocalStorage.getItem("merchant_id");

  return (
    <>
      <Nav
        className="w-100 d-flex justify-content-between"
        style={{ margin: "0 1rem" }}
      >
        <NavItem>
          <NavLink
            to="/"
            className="nav-link"
            style={{ fontWeight: 600, textTransform: "capitalize" }}
          >
            Kartu Keren {merchantType} {merchantId}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="nav-link" onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOut} /> Logout
          </NavLink>
        </NavItem>
      </Nav>
    </>
  );
}

// Komponen BottomTabNavigator
function BottomTabNavigator() {
  const location = useLocation();

  return (
    <nav className="navbar fixed-bottom navbar-light" role="navigation">
      <Nav className="w-100 d-flex flex-row justify-content-around">
        {tabs.map((tab, index) => (
          <NavItem key={`tab-${index}`}>
            <NavLink to={tab.route} className="nav-link">
              <div className="text-center">
                <img
                  src={
                    location.pathname === tab.route ? tab.activeIcon : tab.icon
                  }
                  alt={tab.label}
                  style={{ width: "24px", height: "auto" }}
                />
                <h6 style={{ fontSize: "12px", marginTop: "3px" }}>
                  {tab.label}
                </h6>
              </div>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    </nav>
  );
}

export default Navigation;
