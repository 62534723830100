import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Alert, Button, Form, Image, InputGroup } from "react-bootstrap";
import { login } from "./fetch";
import secureLocalStorage from "react-secure-storage";
import { useState } from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Login() {
  const [showPassword, setShowPassword] = useState(true);
  const [showAlrt, setShowAlert] = useState(false);
  const [showAlrtMess, setShowAlertMess] = useState("");

  const handleClick = async (e) => {
    e.preventDefault();

    const param = {
      email: document.getElementById("email").value,
      password: document.getElementById("password").value,
    };

    try {
      const res = await login(param);
      if (res.status) {
        secureLocalStorage.setItem("token", res.data.token);
        window.location.reload();
      } else {
        setShowAlert(true);
        setShowAlertMess(res.message);
      }
    } catch (error) {
      setShowAlert(true);
      setShowAlertMess(error.message);
    }
  };

  return (
    <Container fluid className="login">
      <Row>
        <Col md={8} className="text-center"></Col>
        <Col md={4} className="text-center" id="container2">
          <Col className="text-center">
            <Image
              src={require("../../assets/hero-login.png")}
              style={{ width: "60%" }}
              className="mb-4"
            />
          </Col>
          <h4 style={{ color: "#1E232C" }}>Super Admin Kartu Keren</h4>
          <h6 style={{ color: "#1E232C" }}>Kelola laporan toko dengan mudah</h6>
          <Form onSubmit={handleClick} className="text-start mb-1">
            <Form.Label className="col-sm-12 text-dark fw-bold">
              Email
            </Form.Label>
            <Form.Control
              type="mail"
              className="input-form mb-3 p-3"
              placeholder="mail@mail.com"
              maxLength={20}
              id="email"
              required
            />
            <Form.Label className="col-sm-12 text-dark fw-bold">
              Kata Sandi
            </Form.Label>
            <InputGroup className="mb-1">
              <Form.Control
                placeholder=""
                type={showPassword ? "password" : ""}
                aria-label="password"
                id="password"
                className="input-form p-3"
                required
              />
              <InputGroup.Text
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  borderWidth: "1px 1px",
                  backgroundColor: "transparent",
                }}
              >
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  color="gray"
                />
              </InputGroup.Text>
            </InputGroup>
            <Col className="text-end mb-4">
              <h4 className="tx-forgot-password">
                <u>Lupa Password?</u>
              </h4>
            </Col>

            <Alert variant="danger" className="mt-3" show={showAlrt}>
              <h4 style={{ color: "red", fontSize: 14 }}>{showAlrtMess}</h4>
            </Alert>
            <Button type="submit">Masuk</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
