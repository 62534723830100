import React from "react";
import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./page/Home/home";
import Navigation from "./component/Navigation";
import Login from "./page/Auth/login";
import PayScanner from "./page/PayScanner/payscanner";
import secureLocalStorage from "react-secure-storage";
import NotFound from "./page/NotFound/notfound";
import Cards from "./page/Cards/cards";
import { BalanceProvider } from "./page/BalanceContext";
import Profile from "./page/Profile/profile";
import CardDetail from "./page/CardDetail/cardDetail";
import Product from "./page/Product/product";
import Merchant from "./page/Merchant/merchant";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            secureLocalStorage.getItem("token") ? (
              <BalanceProvider>
                <Navigation /> <Home />
              </BalanceProvider>
            ) : (
              <Login />
            )
          }
        />

        <Route
          path="/payscanner"
          element={
            secureLocalStorage.getItem("token") ? (
              <>
                <PayScanner />
              </>
            ) : (
              <Login />
            )
          }
        />

        <Route
          path="/card-detail"
          element={
            secureLocalStorage.getItem("token") ? (
              <>
                <CardDetail />
              </>
            ) : (
              <Login />
            )
          }
        />

        <Route
          path="/cards"
          element={
            secureLocalStorage.getItem("token") ? (
              <BalanceProvider>
                <Navigation /> <Cards />
              </BalanceProvider>
            ) : (
              <Login />
            )
          }
        />
        <Route
          path="/products"
          element={
            secureLocalStorage.getItem("token") ? (
              <BalanceProvider>
                <Navigation /> <Product />
              </BalanceProvider>
            ) : (
              <Login />
            )
          }
        />
        <Route
          path="/merchants"
          element={
            secureLocalStorage.getItem("token") ? (
              <BalanceProvider>
                <Navigation /> <Merchant />
              </BalanceProvider>
            ) : (
              <Login />
            )
          }
        />
        <Route
          path="/profile"
          element={
            secureLocalStorage.getItem("token") ? (
              <BalanceProvider>
                <Navigation /> <Profile />
              </BalanceProvider>
            ) : (
              <Login />
            )
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
