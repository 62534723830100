import { GET } from "../../api/api";

async function profile(param) {
  try {
    const res = await GET("merchant/profile", param);
    return res;
  } catch (error) {
    return null;
  }
}

export { profile };
