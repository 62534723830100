import { GET, PUT } from "../../api/api";

async function product(param) {
  try {
    const res = await GET("product", param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function updateProduct(param) {
  try {
    const res = await PUT("product", param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export { product, updateProduct };
